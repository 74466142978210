import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AccountApi } from '../api';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Injectable({
    providedIn: 'root',
})
export class LoginService implements OnDestroy {
    private isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    login$: Observable<boolean> = this.isLoggedIn$ as Observable<boolean>;

    signIn$: Observable<void>;
    signOut$: Observable<void>;

    private destroy$: Subject<void> = new Subject();

    constructor(
        private account: AccountApi,
        private router: Router,
        private localize: LocalizeRouterService,
    ) {
        this.account.user$.pipe(
            takeUntil(this.destroy$),
        ).subscribe(user => {
            const loggedIn = user !== null;
            this.isLoggedIn$.next(loggedIn);
        });

        this.signIn$ = account.signIn$;
        this.signOut$ = account.signOut$;
    }

    get noSyncCart(): boolean {
        return this.account.noSyncCart;
    }

    get isLoggedIn(): boolean {
        return this.isLoggedIn$.value;
    }

    logout(): void {
        this.account.signOut().pipe(takeUntil(this.destroy$)).subscribe(() => {
            const route = this.localize.translateRoute('/');
            this.router.navigateByUrl(route as string).then();
        });
    }

    isVisible$ = new BehaviorSubject<boolean>(false);

    open() {
        this.isVisible$.next(true);
    }

    close() {
        this.isVisible$.next(false);
    }

    openLoginModalAndAwaitLogin(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.isVisible$.next(true);
            const subscription = this.isLoggedIn$.pipe(
                takeUntil(this.destroy$),
            ).subscribe(isLoggedIn => {
                if (isLoggedIn) {
                    console.log('logged in')
                    this.isVisible$.next(false);
                    resolve(true);
                    subscription.unsubscribe();
                }
            });

            this.isVisible$.pipe(
                takeUntil(this.destroy$),
            ).subscribe(isVisible => {
                if (!isVisible && !this.isLoggedIn) {
                    console.log('close')
                    resolve(false);
                    subscription.unsubscribe();
                }
            });
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
