<app-snow [mobile]="true"></app-snow>
<div class="container">
    <div class="mobile-header__body">
        <app-logo [mobile]="true"></app-logo>
        <div class="mobile-header__indicators">
<!--            <app-mobile-contacts></app-mobile-contacts>-->
            <div class="mobile-indicator">
                <button class="mobile-indicator__button" (click)="cart.open()">
                    <span class="mobile-indicator__icon">
                        <app-icon icon="cart-mobile-34"></app-icon>
                        <span
                            *ngIf="cart.quantity$|async"
                            class="mobile-indicator__counter"
                        >{{ cart.quantity$|async }}</span>
                    </span>
                </button>
            </div>
        </div>
        <button class="mobile-header__menu-button" type="button" (click)="menu.open()">
            <app-icon icon="menu-16"></app-icon>
        </button>
    </div>
</div>
<div class="mobile-header__wrapper">
    <div class="container">
        <div
            class="mobile-header__search mobile-search"
            #searchForm
        >
            <form class="mobile-search__body" (submit)="$event.preventDefault(); onSearch(searchInput.value)">
                <label class="sr-only" for="mobile-site-search">{{ 'INPUT_SEARCH_LABEL'|translate }}</label>
                <input
                    type="text"
                    id="mobile-site-search"
                    class="mobile-search__input"
                    placeholder="{{'INPUT_SEARCH_PLACEHOLDER'|translate}}"
                    #searchInput
                >
                <button type="submit" class="mobile-search__button mobile-search__button--search px-2">
                    <app-icon icon="search-20"></app-icon>
                </button>

                <div class="mobile-search__field"></div>
            </form>
            <button
                type="button"
                class="mobile-search__vehicle-picker"
                (click)="openVehiclePicker()"
            >
                <app-icon icon="car-20"></app-icon>
            </button>
        </div>
    </div>
</div>
