export const company = {
    name: 'ParGo',
    author: {
        profile_url: '',
        name: '',
    },
    contacts: {
        address: ['м. Київ, вул. Перемоги, 9-а'],
        phone: ['0 800 300 100', '0 800 200 100'],
        hours: ['Пн-Пт 09:00-18:00', 'Сб-Нд 09:00-16:00'],
        telegram: ['https://t.me/Neroznyi'],
        viber: ['https://viber.com'],
    },
};
