import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HourService } from '../../../../services/hour.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();
    newYear = false;

    @Input() mobile = false;

    constructor(
        private hour: HourService,
    ) {
    }

    ngOnInit(): void {
        this.hour.isNewYearPeriod$.pipe(takeUntil(this.destroy$)).subscribe(value => {
            this.newYear = value;
        })
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
