<button class="clients__button" type="button">
    <span class="clients__button-title">{{ isMenu ? ("TEXT_CLIENTS"|translate) : ("TEXT_CLIENTS"|translate|uppercase) }}</span>
    <span class="clients__button-arrow"><app-icon icon="arrow-rounded-down-9x6"></app-icon></span>
</button>
<div class="clients__menu">
    <div class="clients__body">
        <ul class="clients__list">
            <li class="clients__item" (click)="onItemClick()">
                <a class="clients__link" routerLink="{{'/site/warranty'|localize}}">
                    <span class="clients__link-icon"><app-icon icon="guarantee-20"></app-icon></span>
                    <span class="clients__link-text">{{ 'TEXT_GUARANTEE'|translate }}</span>
                </a>
            </li>
            <li class="clients__item" (click)="onItemClick()">
                <a class="clients__link" routerLink="{{'/site/delivery'|localize}}">
                    <span class="clients__link-icon"><app-icon icon="delivery-20"></app-icon></span>
                    <span class="clients__link-text">{{ 'LINK_DELIVERY_INFORMATION'|translate }}</span>
                </a>
            </li>
            <li class="clients__item" (click)="onItemClick()">
                <a class="clients__link" routerLink="{{'/site/about-us'|localize}}">
                    <span class="clients__link-icon"><app-icon icon="about-us-20"></app-icon></span>
                    <span class="clients__link-text">{{ 'LINK_ABOUT_US'|translate }}</span>
                </a>
            </li>
            <li class="clients__item" (click)="onItemClick()">
                <a class="clients__link" routerLink="{{'/site/offer'|localize}}">
                    <span class="clients__link-icon"><app-icon icon="offer-20"></app-icon></span>
                    <span class="clients__link-text">{{ 'TEXT_OFFER'|translate }}</span>
                </a>
            </li>
            <li class="clients__item" (click)="onItemClick()">
                <a class="clients__link" routerLink="{{'/site/privacy-policy'|localize}}">
                    <span class="clients__link-icon"><app-icon icon="offer-20"></app-icon></span>
                    <span class="clients__link-text">{{ 'LINK_PRIVACY_POLICY'|translate }}</span>
                </a>
            </li>
            <li class="clients__item" (click)="onItemClick()">
                <a class="clients__link" routerLink="{{'/blog'|localize}}">
                    <span class="clients__link-icon"><app-icon icon="blog-20"></app-icon></span>
                    <span class="clients__link-text">{{ 'LINK_BLOG'|translate }}</span>
                </a>
            </li>
        </ul>
    </div>
</div>
