import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { ContactData } from '../interfaces/contact';
import { ContentApi } from '../api';
import { take, takeUntil } from 'rxjs/operators';
import { LoginService } from './login.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class ContactService implements OnDestroy {
    private destroy$: Subject<void> = new Subject();
    private _contactData$: BehaviorSubject<ContactData | undefined> = new BehaviorSubject<ContactData | undefined>(undefined);
    public contactData$ = this._contactData$ as Observable<ContactData | undefined>;

    constructor(
        private api: ContentApi,
        private login: LoginService,
        private translate: TranslateService,
    ) {
        merge(
            this.translate.onLangChange,
            this.login.login$,
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.fetchContactData();
            });
    }

    private fetchContactData() {
        this.api.getContactData()
            .pipe(take(1))
            .subscribe((value) => {
                this._contactData$.next(value);
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
