import { Component, OnDestroy, OnInit } from '@angular/core';
import { company } from '../../../data/company';
import { Observable, Subject } from 'rxjs';
import { Link } from '../../interfaces/link';
import { map } from 'rxjs/operators';
import { UrlService } from '../../services/url.service';
import { LoginService } from '../../services/login.service';
import { ShopCategoriesService } from '../../services/shop-categories.service';

@Component({
    selector: 'app-pargo-footer',
    templateUrl: './footer-pargo.component.html',
    styleUrls: ['./footer-pargo.component.scss'],
})
export class FooterPargoComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();
    theme = company;
    categoriesLinks$!: Observable<Link[]>;

    constructor(
        public url: UrlService,
        public loginService: LoginService,
        private categories: ShopCategoriesService,
    ) {
    }

    get currentYear(): number {
        return new Date().getFullYear();
    }

    ngOnInit(): void {
        const categories = this.categories.categories$;
        this.categoriesLinks$ = categories.pipe(
            map(categories => (
                categories.map(category => ({
                    title: category.name,
                    url: this.url.category(category),
                }))
            )),
        );
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
