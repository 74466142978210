<!--<div class="snowflake-wrapper h-100 w-100 position-absolute overflow-hidden"></div>-->
<app-snow></app-snow>
<div class="container">
    <div class="header">
        <div class="header__topbar-pargo-bg"></div>
        <div class="header__topbar-pargo">
            <app-topbar layout="pargo"></app-topbar>
        </div>

        <app-logo class="header__logo"></app-logo>
        <div class="header__search">
            <app-search></app-search>
        </div>
        <div class="header__indicators">

            <app-indicator
                    icon="cart-19"
                    [label]="'TEXT_INDICATOR_CART_LABEL'|translate"
                    [value]="((cart.quantity$|async) || 0) + ''"
                    trigger="click"
                    (click)="cart.open()"
            >
            </app-indicator>

            <ng-container *ngIf="isPlatformBrowser">

                <button *ngIf="(loginService.login$|async) !== true" class="header-login-button" (click)="loginService.open()">
                    <span>{{'BUTTON_LOGIN'|translate|uppercase}}</span>
                    <span class="header-login-button__secondary">{{'BUTTON_LOGIN_SUBTITLE'|translate}}</span>
                </button>

                <app-indicator
                    *ngIf="(loginService.login$|async) === true"
                    link="/account"
                    icon="person-19"
                    [label]="(userName$|async) || ('TEXT_INDICATOR_ACCOUNT_LABEL'|translate)"
                    [value]="'TEXT_INDICATOR_ACCOUNT_VALUE'|translate"
                    trigger="click"
                    #accountIndicator="indicator"
                >
                    <app-account-menu (closeMenu)="accountIndicator.close()"></app-account-menu>
                </app-indicator>

            </ng-container>
        </div>
    </div>
</div>
