import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, map, shareReplay, Subject, Subscription, timer } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class HourService implements OnDestroy {
    private destroy$: Subject<void> = new Subject();
    private readonly HOUR_IN_MS = 3600000; // 1hour
    // private readonly HOUR_IN_MS = 10000; test 10sec
    private _isNewYearPeriod = new BehaviorSubject<boolean>(false);

    public isNewYearPeriod$ = this._isNewYearPeriod.asObservable();

    constructor(@Inject(PLATFORM_ID) protected platformId: any) {
        this.startTimer();
    }

    public get isNewYearPeriod() {
        return this._isNewYearPeriod.value;
    }

    private startTimer(): void {
        if (isPlatformBrowser(this.platformId)) {
            timer(0, this.HOUR_IN_MS).pipe(
                takeUntil(this.destroy$),
                tap(() => this.executeScheduledTasks()),
                finalize(() => {
                    this.startTimer();
                }),
            ).subscribe();
        }
    }

    private executeScheduledTasks(): void {
        this.checkNewYearPeriod();
        console.log('hourly schedule task completed');
        // Место для других периодических задач
    }

    private checkNewYearPeriod(): void {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const periodStart = new Date(currentYear, 11, 10);
        const periodEnd = new Date(currentYear + 1, 0, 1);

        const isNewYearPeriod = currentDate >= periodStart && currentDate <= periodEnd;
        this._isNewYearPeriod.next(isNewYearPeriod);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this._isNewYearPeriod.complete();
    }
}
