import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PickupPoint } from '../../../../interfaces/pickup_point';
import { ContactService } from '../../../../services/contact.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContactData } from '../../../../interfaces/contact';

@Component({
    selector: 'app-contacts-pargo',
    templateUrl: './contacts-pargo.component.html',
    styleUrls: ['./contacts-pargo.component.scss'],
})
export class ContactsPargoComponent implements OnInit, OnDestroy{
    destroy$: Subject<void> = new Subject<void>();

    data?: ContactData | null;

    @HostBinding('class.pargo-footer-contacts') classFooterContacts = true;

    constructor(
        private translate: TranslateService,
        public contact: ContactService,
    ) { }

    ngOnInit() {
        this.contact.contactData$.pipe(takeUntil(this.destroy$)).subscribe(contactData => {
            this.data = contactData;
        })
    }

    pointName(point: PickupPoint): string {
        return point.partner
            ? `${point.address} (${this.translate.instant('TEXT_PARTNER_WAREHOUSE')})`
            : point.address;
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
