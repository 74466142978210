import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HourService } from '../../../../services/hour.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-snow',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './snow.component.html',
  styleUrls: ['./snow.component.scss'],
})
export class SnowComponent implements OnInit, OnDestroy {
    destroy$: Subject<void> = new Subject<void>();

    @Input() maxSnowflakes = 30;
    @Input() mobile = false;

    snowflakes: Array<{
        display: string;
        size: number;
        left: string;
        opacity: number;
        transform: string;
        transition: string;
        animationTimeout?: any;
    }> = [];

    private interval: any;

    constructor(
        private elementRef: ElementRef,
        private hour: HourService,
    ) {}

    ngOnInit() {
        if (this.mobile) {
            this.maxSnowflakes = 12;
        }
        this.hour.isNewYearPeriod$.pipe(takeUntil(this.destroy$)).subscribe(value => {
            value ? this.start() : this.stop();
        })
    }

    ngOnDestroy() {
        this.stop();
        this.destroy$.next();
        this.destroy$.complete();
    }

    start() {
        this.createSnowflakePool();
        this.interval = setInterval(() => this.animateSnowflake(), 200);
    }

    stop() {
        clearInterval(this.interval);
        this.snowflakes.forEach(snowflake => {
            if (snowflake.animationTimeout) {
                clearTimeout(snowflake.animationTimeout);
            }
        });
        this.snowflakes = [];
    }

    private createSnowflakePool() {
        this.snowflakes = Array(this.maxSnowflakes).fill(null).map(() => ({
            display: 'none',
            size: 0,
            left: '0%',
            opacity: 0,
            transform: 'translate3d(0, -10px, 0)',
            transition: '',
        }));
    }

    private animateSnowflake() {
        const container = this.elementRef.nativeElement.querySelector('.snowflake-container');
        if (!container) return;

        const inactiveSnowflake = this.snowflakes.find(s => s.display === 'none');
        if (!inactiveSnowflake) return;

        const size = Math.random() * 5 + 5;
        const duration = Math.random() * 3 + 2;

        Object.assign(inactiveSnowflake, {
            display: 'block',
            size: size,
            left: `${Math.random() * 100}%`,
            opacity: 0.5 + Math.random() * 0.5,
            transition: `transform ${duration}s linear`,
            transform: 'translate3d(0, -10px, 0)',
        });

        requestAnimationFrame(() => {
            inactiveSnowflake.transform = `translate3d(${Math.random() * 20}px, ${container.offsetHeight}px, 0)`;
        });

        inactiveSnowflake.animationTimeout = setTimeout(() => {
            inactiveSnowflake.display = 'none';
        }, duration * 1000);
    }
}
