<div class="snowflake-container">
    <div *ngFor="let snowflake of snowflakes"
         class="snowflake"
         [style.display]="snowflake.display"
         [style.width.px]="snowflake.size"
         [style.height.px]="snowflake.size"
         [style.left]="snowflake.left"
         [style.opacity]="snowflake.opacity"
         [style.transform]="snowflake.transform"
         [style.transition]="snowflake.transition"
         [style.z-index]="mobile ? '-1' : 'unset'"
    >
    </div>
</div>
