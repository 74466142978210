<a
    routerLink="{{'/'|localize}}"
    class="logo"
>
    <div class="logo__slogan">
        {{ 'TEXT_SLOGAN'|translate }}
    </div>
    <div
        class="logo__image"
        [class.mobile-logo]="mobile"
    >
        <!-- logo -->
        <app-icon *ngIf="!newYear; else newYearLogo" icon="logo"></app-icon>

        <ng-template #newYearLogo>
            <div class="ny-logo">
                <img ngSrc="assets/images/ny/logo.webp" alt="pargo" height="220" width="630" class="ny-logo__img"/>
                <img ngSrc="assets/images/ny/hat.webp" alt="hat" height="112" width="134" class="ny-logo__hat"/>
                <img ngSrc="assets/images/ny/bell.webp" alt="hat" height="38" width="36" class="ny-logo__bell-left"/>
                <img ngSrc="assets/images/ny/bell.webp" alt="hat" height="38" width="36" class="ny-logo__bell-right"/>
            </div>
        </ng-template>
        <!-- logo / end -->
    </div>
</a>
