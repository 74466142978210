import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class CustomReuseStrategy implements RouteReuseStrategy {
    private forceReload = false;

    setForceReload(flag: boolean): void {
        this.forceReload = flag;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return null;
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        // Если перезагрузка запрошена, отключаем переиспользование
        if (this.forceReload) {
            this.forceReload = false; // Сбрасываем флаг
            return false;
        }
        // Иначе, сравниваем конфигурации маршрутов - поведение по-умолчанию
        return future.routeConfig === curr.routeConfig;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    }
}
