import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GoogleLoginProvider, GoogleSigninButtonModule, SocialAuthService } from '@abacritt/angularx-social-login';
import { HasErrorPipe } from '../../pipes/has-error.pipe';
import { IsInvalidPipe } from '../../pipes/is-invalid.pipe';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { TranslateModule } from '@ngx-translate/core';
import { AccountApi } from '../../../../api';
import { Subject, throwError } from 'rxjs';
import { mustMatchValidator } from '../../../../functions/validators/must-match';
import { catchError, filter, finalize, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { GtmRegister } from '../../../../services/gtm.service';

@Component({
    selector: 'app-sign-up-form',
    standalone: true,
    imports: [
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        GoogleSigninButtonModule,
        HasErrorPipe,
        IsInvalidPipe,
        NgxMaskDirective,
        ReactiveFormsModule,
        TranslateModule,
    ],
    templateUrl: './sign-up-form.component.html',
    styleUrls: ['./sign-up-form.component.scss'],
    providers: [provideNgxMask()],
})
export class SignUpFormComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    showSignUpPassword = false;
    showSignUpConfirm = false;

    registerForm!: UntypedFormGroup;
    registerInProgress = false;

    @Input() showHeader = true;

    @Output() registerSuccess = new EventEmitter<GtmRegister>();
    @Output() registerFail = new EventEmitter<any>();

    constructor(
        private fb: UntypedFormBuilder,
        private account: AccountApi,
        private socialAuthService: SocialAuthService,
    ) {
    }

    ngOnInit() {
        this.registerForm = this.fb.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            phone: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', [Validators.required]],
        }, {
            validators: [mustMatchValidator('password', 'confirmPassword')],
        });

        this.socialAuthService.authState
            .pipe(takeUntil(this.destroy$), filter(value => value != null))
            .subscribe(value => {
                if (value.provider == GoogleLoginProvider.PROVIDER_ID) {
                    console.log('google sign up')
                    this.loginWithGoogle(value.idToken);
                }
            });
    }

    loginWithGoogle(tokenId: string) {
        this.registerInProgress = true;
        this.account.signInWithGoogle(tokenId)
            .pipe(
                finalize(() => this.registerInProgress = false),
                takeUntil(this.destroy$),
                catchError(err => {
                    this.account.loggedInAsSocial = false;
                    return this.failHandler(err)
                }),
            ).subscribe((user) => {
            this.account.loggedInAsSocial = true;
            this.registerSuccess.emit({
                phone: user.phone && user.phone.length == 10 ? `+38${user.phone}` : user.phone,
                email: user.email,
                source: 'google',
            });
        });
    }

    register(): void {
        this.registerForm.markAllAsTouched();

        if (this.registerInProgress || this.registerForm.invalid) {
            return;
        }

        this.registerInProgress = true;

        this.account.signUp(
            this.registerForm.value.firstName,
            this.registerForm.value.lastName,
            this.registerForm.value.phone,
            this.registerForm.value.email,
            this.registerForm.value.password,
        ).pipe(
            finalize(() => this.registerInProgress = false),
            takeUntil(this.destroy$),
            catchError(err => this.failHandler(err)),
        ).subscribe(() => this.registerSuccess.emit({
            phone: this.registerForm.value.phone && this.registerForm.value.phone.length == 10 ? `+38${this.registerForm.value.phone}` : this.registerForm.value.phone,
            email: this.registerForm.value.email,
            source: 'form',
        }));
    }

    failHandler(err: any) {
        this.registerFail.emit(err);

        if (err instanceof HttpErrorResponse) {
            this.registerForm.setErrors({
                server: `ERROR_API_${err.error.message}`,
            });
        } else {
            alert(err);
        }
        return throwError(() => err);
    }

    toggleSignUpPasswordVisibility(): void {
        this.showSignUpPassword = !this.showSignUpPassword;
    }

    toggleSignUpConfirmVisibility(): void {
        this.showSignUpConfirm = !this.showSignUpConfirm;
    }

    ngOnDestroy() {
        console.log('destroy sign up')
        this.destroy$.next();
        this.destroy$.complete();
    }

    protected readonly faEyeSlash = faEyeSlash;
    protected readonly faEye = faEye;
}
